import UIKitElement from '@/ui-kit/js/UIKitElement';
import UIRatingHTML from '@/ui-kit/js/components/ui-rating/UIRating.html';

import './UIRating.scss';

class UIRating extends UIKitElement {
  constructor () {
    super('ui-rating', {
      classes: ['ui-rating'],
    });
  }

  connectedCallback (): void {
    this.render();
  }

  private render (): void {
    const size = this.getAttribute('size') ?? '1';
    const score = this.getAttribute('score') ?? '0';
    const reviews = this.getAttribute('reviews') ?
      `(${this.getAttribute('reviews')})` :
      '';
    const activeColor = this.getAttribute('active-color') ?? 'gray-blue-800';
    const inactiveColor = this.getAttribute('inactive-color') ?? 'secondary-100';

    this.container.style.setProperty(`--${this.component}-size-modifier`, size);
    this.container.style.setProperty(`--${this.component}-score`, score);
    this.container.style.setProperty(`--${this.component}-active-color`, `var(--ui-colors-${activeColor})`);
    this.container.style.setProperty(`--${this.component}-inactive-color`, `var(--ui-colors-${inactiveColor})`);
    this.container.innerHTML = this.interpolate(UIRatingHTML, {
      component: this.component,
      reviews,
    });
  }
}

export default UIRating;
