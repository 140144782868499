
import { defineCustomElement } from 'vue';

import UICheckbox from '@/ui/components/ui-checkbox/UICheckbox.vue';
import UIModal from '@/ui/components/ui-modal/UIModal.vue';
import UIRating from '@/ui/components/ui-rating/UIRating';
import '@/ui/components/ui-price/index';
import '@/ui/components/ui-product-tuple/index.ts';
import '@/ui/components/ui-product-swiper/index';

if (!customElements.get('ui-checkbox')) {
	customElements.define('ui-checkbox', defineCustomElement(UICheckbox));
}
if (!customElements.get('ui-modal')) {
	customElements.define('ui-modal', defineCustomElement(UIModal));
}
if (!customElements.get('ui-rating')) {
	customElements.define('ui-rating', UIRating);
}
