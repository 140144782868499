import Route from '@/js/utils/route';
import { FetchApi } from '@/ui/services';

class ClickLoggerService extends FetchApi {
  async log (payload: {
    productId: number;
    query: string;
  }): Promise<void> {
    const res = await this.post(`${Route.get('clientApi.click')}?new-elk=1`, {
      productId: payload.productId,
      usedQuery: payload.query,
    })

    if (res.response.status !== 200) {
      throw res.content;
    }
  }
}

const clickLoggerService = new ClickLoggerService;

export default clickLoggerService;
