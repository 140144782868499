export enum UICheckboxValueEnum {
  Unchecked = '0',
  Checked = '1',
  Partial = '2',
}

export enum UICheckboxSizeEnum {
  Medium = 'md',
  Large = 'lg',
}

export interface UICheckboxEventMapInterface {
  'checkbox_set': CustomEvent<UICheckboxValueEnum>;
  'checkbox_change': CustomEvent<UICheckboxValueEnum>;
}
