import { UIProductTupleBadgeVariants } from '@/ui-kit/js/components/ui-product-tuple/types';

export const badgeVariants: UIProductTupleBadgeVariants = {
  'top': {
    slug: '/bestseller',
    text: 'TOP',
    textColor: 'yellow-900',
    backgroundColor: 'yellow-400',
  },
  'announcement': {
    slug: '/zapowiedzi',
    text: 'Zapowiedź',
    textColor: 'white-500',
    backgroundColor: 'primary-400',
  },
  'novelty': {
    slug: '/nowosci',
    text: 'Nowość',
    textColor: 'white-500',
    backgroundColor: 'green-500',
  },
};
