import UIKitElement from '@/ui-kit/js/UIKitElement';
import UIPriceHTML from '@/ui-kit/js/components/ui-price/UIPrice.html';

import './UIPrice.scss';

class UIPrice extends UIKitElement {
  constructor () {
    super('ui-price', {
      tag: 'span',
      classes: ['ui-price'],
    });
  }

  connectedCallback (): void {
    this.render();
  }

  private render (): void {
    const size = this.getAttribute('size') ?? '1';
    const priceAttribute: number = parseFloat(this.getAttribute('price') ?? '0');
    const price = priceAttribute ?? 'brak ceny';
    const currency = priceAttribute ? this.getAttribute('currency') ?? 'PLN' : 'PLN';
    const highlight = this.hasAttribute('highlight');
    const highlightColor = this.getAttribute('highlight-color') ?? 'yellow-400';
    const textColor = this.getAttribute('text-color') ?? 'gray-blue-800';
    const paddingX: string = this.getAttribute('padding-x') ?? '4';
    const paddingY: string = this.getAttribute('padding-y') ?? '0';
    const fontWeight: number = parseInt(this.getAttribute('font-weight') ?? '500') ?? 500;

    this.container.style.setProperty(`--${this.component}-padding-x-modifier`, paddingX);
    this.container.style.setProperty(`--${this.component}-padding-y-modifier`, paddingY);
    this.container.style.setProperty(`--${this.component}-font-weight`, String(fontWeight));
    this.container.style.setProperty(`--${this.component}-size-modifier`, size);
    this.container.style.setProperty(`--${this.component}-text-color`, `var(--ui-colors-${textColor})`);

    if (highlight) {
      this.container.classList.add(`${this.component}--highlight`);
      this.container.style.setProperty(`--${this.component}-highlight-color`, `var(--ui-colors-${highlightColor})`);
    }

    this.container.innerHTML = this.interpolate(UIPriceHTML, {
      component: this.component,
      currency: currency,
      price: Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: currency,
      }).format(price),
    });
  }
}

export default UIPrice;
