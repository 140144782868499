import isCsr from '@/ui/utils/isCsr';

export type FetchApiResponse<T> = Promise<{
  content: T,
  response: Response,
}>;
import * as Cookies from 'es-cookie';
export class FetchApi {
  public async post<Payload, Return> (uri: string, payload: Payload): FetchApiResponse<Return> {
    const res = await fetch(uri, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'X-XSRF-TOKEN': isCsr ? Cookies.get('XSRF_TOKEN') ?? '' : '',
      },
      body: JSON.stringify({_token: window.csrfToken, ...payload}),
    });

    return {
      content: await res.json() as Return,
      response: res,
    }
  }

  public async get<Return> (uri: string): FetchApiResponse<Return> {
    const res = await fetch(uri, {
      method: 'get',
      headers: {
        'Content-type': 'application/json',
      },
    });

    return {
      content: await res.json() as Return,
      response: res,
    }
  }
}
