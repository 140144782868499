
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from 'vue';
import {ColorDensity, ColorPalette} from '@/ui/types/colors';
import useUIKit from '@/ui-kit/js/composable/useUIKit';
import useLockBody from '@/ui/composable/useLockBody';
import useModal from '@/ui/composable/useModal';
import { UIKitComponent } from '@/ui/types/components';
import useAuth from '@/ui/composable/useAuth';

export default defineComponent({
  name: 'UIModal',
  props: {
    base: {
      type: Node,
      required: true,
    },
    closeBtn: {
      type: Boolean,
      default: () => true,
    },
    width: {
      type: Number,
      default: () => 400,
    },
    modalId: {
      type: String,
      required: true,
    },
    modalComponent: {
      type: String as PropType<UIKitComponent>,
      default: undefined,
    },
    bgColor: {
      type: String as PropType<ColorPalette>,
      default: ColorPalette.White,
    },
    bgColorDensity: {
      type: String as PropType<ColorDensity>,
      default: '500',
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    headingIcon: {
      type: Boolean,
      default: false,
    },
    headingSide: {
      type: Boolean,
      default: false,
    },
    instant: {
      type: Boolean,
      default: false,
    },
    disableClose: {
      type: Boolean,
      default: false,
    },
    disableMask: {
      type: Boolean,
      default: false,
    },
    disableOutclick: {
      type: Boolean,
      default: false,
    },
    mobileFullScreen: {
      type: Boolean,
      default: false,
    },
    requiredLogin: {
      type: Boolean,
      default: false,
    },
  },

  setup (props) {
    const { pushLock, popLock } = useLockBody();
    const { pushModal, toggleAsyncModal } = useModal();
    const { isUserAuthenticated } = useAuth();

    const isVisible = ref(false);
    const component = ref<HTMLElement>();
    const hostElement = ref<HTMLElement>();

    let bodyLockId: string;

    const toggleModal = async (currentPayload?: unknown): Promise<void> => {
      if (!isVisible.value && props.requiredLogin && !isUserAuthenticated.value) {
        await toggleAsyncModal('ui-login-modal', 'ui-login-modal', {
          redirect: window.location.href,
          payload: {
            modalId: props.modalId,
            modalComponent: props.modalComponent,
            payload: currentPayload,
          },
        });

        return;
      }

      if (!isVisible.value) {
        pushModal(props.base);
      }

      hostElement.value?.classList.toggle('ui-modal--opened');
      isVisible.value && hostElement.value?.dispatchEvent(
        new CustomEvent('close'),
      );
      isVisible.value = !isVisible.value;
    }

    const handleModalToggleCustomEvent = async (event: Event): Promise<void> => {
      const detail = (event as CustomEvent<{
        modalId: string,
        payload?: Record<string, unknown>,
        reload?: boolean,
      }>).detail;
      if (detail.modalId !== props.modalId) {
        return;
      }
      if (!detail.reload || !isVisible.value) {
        await toggleModal(detail.payload);
      }

      if (detail.payload) {
        hostElement.value?.dispatchEvent(
          new CustomEvent('update_payload', {
            detail: detail.payload,
          }),
        );

        'fallbackEvent' in detail.payload && document.dispatchEvent(
          new CustomEvent(detail.payload.fallbackEvent as string),
        );
      }
    };

    const closeOnOverlayClick = async (event: MouseEvent): Promise<void> => {
      event.target === component.value && await toggleModal();
    }

    onMounted(async () => {
      document.addEventListener('modal_toggle', handleModalToggleCustomEvent);
      const { host } = await useUIKit(component, 'ui-modal');

      if (host) {
        hostElement.value = host;
      }

      hostElement.value?.dispatchEvent(new CustomEvent('mounted'));

      if (props.instant) {
        await toggleModal();
      }
    });

    onBeforeUnmount(() => {
      document.removeEventListener('modal_toggle', handleModalToggleCustomEvent);
    });

    watch(
      () => isVisible.value,
      ()=> {
        if (isVisible.value) {
          bodyLockId = pushLock();
        } else {
          popLock(bodyLockId);
        }
      },
    );

    return {
      component,
      isVisible,
      toggleModal,
      closeOnOverlayClick,
    }
  },
});
