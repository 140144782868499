
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  computed,
  toRef,
  watch,
  PropType,
} from 'vue';
import useUIKit from '@/ui/composable/useUIKit';
import usePlaceholder from '@/ui/composable/usePlaceholder';
import {
  UICheckboxSizeEnum,
  UICheckboxValueEnum,
} from './types';

export default defineComponent({
  props: {
    id: {
      type: String,
      default: undefined,
    },
    value: {
      type: String as PropType<UICheckboxValueEnum>,
      default: UICheckboxValueEnum.Unchecked,
    },
    size: {
      type: String as PropType<UICheckboxSizeEnum>,
      default: UICheckboxSizeEnum.Medium,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    help: {
      type: String,
      default: null,
    },
  },

  setup (props) {
    const placeholder = usePlaceholder();
    const component = ref<HTMLElement>();
    const hostElement = ref<HTMLElement | null>();
    const value = toRef(props, 'value');
    const modelValue = ref(value.value);

    const checked = computed(() => modelValue.value !== UICheckboxValueEnum.Unchecked);

    const handleToggle = (
      event: CustomEvent<UICheckboxValueEnum> | MouseEvent,
    ): void => {
      if (event instanceof MouseEvent) {
        if (props.disabled) {
          return;
        }

        modelValue.value = modelValue.value === UICheckboxValueEnum.Unchecked ?
          UICheckboxValueEnum.Checked :
          UICheckboxValueEnum.Unchecked;
      } else {
        modelValue.value = event.detail;
      }

      hostElement.value?.dispatchEvent(new CustomEvent('checkbox_change', {
        detail: modelValue.value,
      }))
    }

    onMounted(async () => {
      const { host } = await useUIKit(component, 'ui-checkbox');
      placeholder.init(component);

      hostElement.value = host;
      host?.addEventListener('click', handleToggle);
      host?.addEventListener('checkbox_set', handleToggle);
    });

    onUnmounted(() => {
      hostElement.value?.removeEventListener('click', handleToggle);
      hostElement.value?.removeEventListener('checkbox_set', handleToggle);
    })

    watch(
      () => props.value,
      () => modelValue.value = props.value,
    )

    return {
      UICheckboxSizeEnum,
      UICheckboxValueEnum,
      placeholder,
      component,
      modelValue,
      checked,
      handleToggle,
    }
  },
});
