import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "-container" }
const _hoisted_2 = ["id", "value", "checked"]
const _hoisted_3 = {
  key: 0,
  class: "ui-t-gray-500 ui-t-regular"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "component",
    class: _normalizeClass(["ui-checkbox", {
      '--lg': _ctx.size === _ctx.UICheckboxSizeEnum.Large,
      '--disabled': _ctx.disabled,
      '--checked': _ctx.checked,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass([{
          '--checked': _ctx.checked && _ctx.modelValue === _ctx.UICheckboxValueEnum.Checked,
          'ui-t-gray-300': _ctx.disabled,
          'ui-t-secondary-600': !_ctx.disabled,
        }, "ui-icon ui-icon-check -icon"])
      }, null, 2 /* CLASS */),
      _createElementVNode("span", {
        class: _normalizeClass(["ui-icon ui-icon-minus", _ctx.disabled ? 'ui-t-gray-300': 'ui-t-secondary-600'])
      }, null, 2 /* CLASS */),
      _createElementVNode("input", {
        id: _ctx.id,
        type: "checkbox",
        class: "-checkbox",
        value: _ctx.value,
        checked: _ctx.modelValue === _ctx.UICheckboxValueEnum.Checked
      }, null, 8 /* PROPS */, _hoisted_2)
    ]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["-label ui-t-gray-700 ui-pl-1", _ctx.size ===_ctx.UICheckboxSizeEnum.Large ? 'ui-t-md' : 'ui-t-sm'])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1 /* TEXT */),
          (_ctx.help)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.help), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_ctx.placeholder && !_ctx.placeholder.componentLoaded.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          innerHTML: _ctx.placeholder.render(
        `0% 0%, 100% 0%, 100% 100%, 0% 100%`
      )
        }, null, 8 /* PROPS */, _hoisted_4))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}